
(function(d, script) {
    if (CSS.supports("background: paint(something)")) return;

    let t1;

    const playAnimation = (firstTime) => {
        if (!t1) return;
        if (t1.isActive()) return;
        
        if (firstTime === true){
            t1.play();
            return;
        }
        t1.reversed(!t1.reversed());
        t1.resume();
    };

    script = d.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {
        t1 = gsap.timeline({
            paused: true
        }).to("html", {
            "--line-thickness": .8, duration: 5,  ease: "sine.out"
        });
        document.querySelector(".hero-image-container").addEventListener("mouseenter", playAnimation);
        playAnimation(true);
    };
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.9.1/gsap.min.js";
    d.getElementsByTagName('head')[0].appendChild(script);
}(document));